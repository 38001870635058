import { useEffect, useState } from 'react';
import {
  DataGridPremium,
  GridColDef,
  gridExpandedSortedRowEntriesSelector,
  GridFilterModel,
  GridPaginationModel,
  GridRowId,
  GridSortModel,
  GridValidRowModel,
  GridRowSelectionModel,
  GridAggregationModel,
  useGridApiRef,
  GridColumnVisibilityModel,
} from '@mui/x-data-grid-premium';
import {
  DataGridToolbar,
  ExposedToolbarProps,
} from './Toolbar/DataGridToolbar';
import { Stack, useTheme } from '@mui/material';
import { NoRowsOverlay } from './Overlays/NoRowsOverlay';
import { LoadingIcon } from '../Widgets/LoadingIcon';
import { useGetRowCounts } from './useGetRowCounts';
import { formatNumber, pluralize } from 'utils/util';
import { ValueGetterFunctions } from 'workers/getRowCounts';
import {
  useFormatCustomColumns,
  getColumnVisibilityModelFromColumns,
  getColumnVisibilityModelForExportOnlyColumns,
} from './useFormatCustomColumns';
import { FilterChipItem } from './Buttons/FilterButton/DataGridFilterButtonChip';
import { GridColDefPremium } from '@mui/x-data-grid-premium/typeOverloads';

// #region Types

interface DataGridProviderProps<T extends GridValidRowModel> {
  rows: T[];
  columns: CustomGridColDef<T>[];
  rowsLoading?: boolean;
  columnsLoading?: boolean;
  singularRowName?: string; // the singular (not plural) name of each row (e.g. attendee, prospect, etc.)
  slotProps?: {
    datagrid?: Partial<
      Omit<
        React.ComponentProps<typeof DataGridPremium<T>>,
        | 'filterModel'
        | 'paginationModel'
        | 'columnVisibilityModel'
        | 'aggregationModel'
        | 'sortModel'
        | 'rowSelectionModel'
      >
    > & {
      height?: number; // height of the datagrid in pixels, default 500
      selectable?: boolean; // whether the rows are selectable
      noRowsOverlayText?: string; // text to show when there are no rows
      noResultsOverlayText?: string; // text to show when there are no results
      onFilteredRowsChange?: (filteredRows: FilteredRow<T>[]) => void;
    };
    toolbar?: ExposedToolbarProps;
  };
  // initial state of the DataGrid
  initialState?: {
    filterModel?: GridFilterModel;
    paginationModel?: GridPaginationModel;
    sortModel?: GridSortModel;
    rowSelectionModel?: GridRowSelectionModel;
    columnVisibilityModel?: GridColumnVisibilityModel;
    aggregationModel?: GridAggregationModel;
  };
  // to save the various states of the DataGrid to the backend
  stateChangeCallbacks?: {
    onFilterModelChange?: (newModel: GridFilterModel) => void;
    onSortModelChange?: (newModel: GridSortModel) => void;
    onColumnVisibilityModelChange?: (
      newModel: GridColumnVisibilityModel
    ) => void;
    onAggregationModelChange?: (newModel: GridAggregationModel) => void;
  };
}

export interface FilteredRow<T extends GridValidRowModel> {
  id: GridRowId;
  model: T | GridValidRowModel;
}

export type CustomGridColDef<T extends GridValidRowModel> = GridColDef<T> &
  GridColDefPremium<T> & {
    hidden?: boolean; // whether this column is hidden
    position?: number; // the position of this column (any arbitrary number)
    templateType?: 'currency' | 'owner' | 'owners' | 'numberBucket' | 'status'; // apply the various templates to the column
    defaultFilter?: boolean; // whether this column filter is shown by default
    hideFilterCount?: boolean; // whether to hide the filter counts
    hideFilterSearchBox?: boolean; // whether to hide the search box in the filter
    hideBlankFilterChip?: boolean; //hide 'None' filter chip (aka the blank one)
    filterSearchBoxPlaceholder?: string; // placeholder for the search box in the filter
    filterMaxChipCount?: number; // maximum number of chips to show in the filter (default 25)
    filterChipTruncateLength?: number; // truncate the filter chip label to this length (default 20)
    filterChipSort?: (labelA: string, labelB: string) => number; // custom sort function for the filter chips
    filterChipBlankText?: string; // text to show when there is no value for the filter chip (default "None")
    filterOperatorToUse?: string; // the filter operator to use for this column (default "isAnyOf")
    filterChipDisableShowAll?: boolean; // whether to disable the "Show All" button inside the filter
    getFilterChipStartIcon?: (value: unknown) => React.ReactNode; // custom start icon for the filter chip (takes in the value of the ChipItem)
    getFilterChipLabel?: (value: unknown) => string; // custom function to get the label for the filter chip (takes in the value of the ChipItem)
    getFilterButtonLabel?: (
      selected: boolean,
      chipItems: FilterChipItem[]
    ) => string | null; // custom function to get the label for the filter button (if it's selected or not)
    valueGetterId?: keyof ValueGetterFunctions<T>; // the keyof the value getter function to use
    valueGetterKey?: string | number; // the same key that's used to get the value returned in getFilterChipLabel and getFilterChipStartIcon
    booleanLabel?: [string, string]; // the labels for the boolean filter chips (default ['True', 'False'])
  };

// #endregion

export const DEFAULT_VALUES_FOR_PROPS = {
  position: 1000,
  amountAtWhichShowAllButtonIsShown: 100,
  filterMaxChipCount: 25,
  filterChipTruncateLength: 20,
  filterChipBlankText: 'None',
  filterOperatorToUse: 'isAnyOf',
  booleanLabel: ['True', 'False'],
};

export const DataGridProvider = <T extends GridValidRowModel>({
  rows,
  columns,
  rowsLoading,
  columnsLoading,
  slotProps,
  singularRowName,
  initialState,
  stateChangeCallbacks,
}: DataGridProviderProps<T>) => {
  const theme = useTheme();
  const apiRef = useGridApiRef();

  // various models needed for the DataGrid
  const [aggregationModel, setAggregationModel] =
    useState<GridAggregationModel>(initialState?.aggregationModel ?? {});
  const [filterModel, setFilterModel] = useState<GridFilterModel>(
    initialState?.filterModel ?? {
      items: [],
    }
  );
  useEffect(() => {
    setFilterModel(initialState?.filterModel ?? { items: [] });
  }, [initialState?.filterModel]);

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>(
    initialState?.paginationModel ?? {
      pageSize: 10,
      page: 0,
    }
  );
  const [sortModel, setSortModel] = useState<GridSortModel>(
    initialState?.sortModel ?? []
  );
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>(initialState?.rowSelectionModel ?? []);
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>(
      initialState?.columnVisibilityModel ??
        getColumnVisibilityModelFromColumns(columns)
    );
  const columnVisbilityModelForExportOnlyColumns =
    getColumnVisibilityModelForExportOnlyColumns(columns);

  // check for various custom column definitions and format accordingly
  const { formattedColumns, teamMembersLoading } = useFormatCustomColumns({
    columns,
  });

  const isLoading = rowsLoading || columnsLoading || teamMembersLoading;

  const [filteredRows, setFilteredRows] = useState<FilteredRow<T>[]>([]);

  // subscribe to the filterModelChange event to update the filtered rows
  // https://github.com/mui/mui-x/issues/1106
  useEffect(() => {
    let unsubscribe: () => void;
    const handleStateChange = () => {
      unsubscribe?.();
      const fRows = gridExpandedSortedRowEntriesSelector(
        apiRef.current?.state ?? {},
        apiRef.current?.instanceId ?? ''
      );
      setFilteredRows(fRows);

      // call the onFilteredRowsChange callback if it exists
      if (slotProps?.datagrid?.onFilteredRowsChange) {
        slotProps.datagrid.onFilteredRowsChange(fRows);
      }
      unsubscribe?.();
    };
    return apiRef.current.subscribeEvent?.(
      'filterModelChange',
      () =>
        (unsubscribe = apiRef.current.subscribeEvent(
          'stateChange',
          handleStateChange
        ))
    );
  }, [apiRef, slotProps?.datagrid]);

  const {
    rowCounts: totalRowCounts,
    isCalculating: isCalculatingTotalRowCounts,
  } = useGetRowCounts<T>({
    rows,
    columns: formattedColumns,
    loading: isLoading,
  });

  const skipFilteredRowCounts =
    isCalculatingTotalRowCounts ||
    (filterModel.items.length === 0 &&
      (!filterModel.quickFilterValues ||
        filterModel.quickFilterValues?.length === 0));
  const {
    rowCounts: filteredRowCounts,
    isCalculating: isCalculatingFilteredRowCounts,
  } = useGetRowCounts<T>({
    rows: filteredRows,
    columns: formattedColumns,
    // skip if we're still calculating the total row counts or if there is no filtered rows
    skip: skipFilteredRowCounts,
    loading: isLoading,
  });

  // add any unwanted props to remove from the DataGrid (or add to the Omit in the type definition up top)
  const { slots, ...cleanedProps } = slotProps?.datagrid ?? {};

  return (
    <Stack spacing={2}>
      <DataGridPremium
        sx={{
          '& .MuiDataGrid-main': {
            backgroundColor: theme.palette.common.white,
            height: slotProps?.datagrid?.height ?? 500,
          },
          // when there are only a few rows, the virtual scroller doesn't take up the full height
          '.MuiDataGrid-virtualScroller': {
            height: slotProps?.datagrid?.height ?? 500,
          },
          // a little padding to the selected row count
          '& .MuiDataGrid-selectedRowCount': {
            pl: 4,
          },
        }}
        initialState={
          initialState
            ? {
                filter: {
                  filterModel: initialState.filterModel,
                },
                sorting: {
                  sortModel: initialState.sortModel,
                },
                columns: {
                  columnVisibilityModel: initialState.columnVisibilityModel,
                },
                aggregation: {
                  model: initialState.aggregationModel,
                },
              }
            : undefined
        }
        apiRef={apiRef}
        loading={isLoading}
        // rows
        rows={rows}
        disableRowGrouping
        // columns
        columns={formattedColumns}
        disableColumnFilter
        disableMultipleColumnsSorting
        disableColumnSelector
        columnVisibilityModel={{
          ...columnVisibilityModel,
          ...columnVisbilityModelForExportOnlyColumns,
        }}
        onColumnVisibilityModelChange={(newModel) => {
          setColumnVisibilityModel(newModel);
          stateChangeCallbacks?.onColumnVisibilityModelChange?.(newModel);
        }}
        // aggregation model
        aggregationModel={aggregationModel}
        onAggregationModelChange={(newModel) => {
          setAggregationModel(newModel);
          stateChangeCallbacks?.onAggregationModelChange?.(newModel);
        }}
        // filter model
        filterModel={filterModel}
        onFilterModelChange={(newModel) => {
          if (stateChangeCallbacks?.onFilterModelChange) {
            stateChangeCallbacks?.onFilterModelChange?.(newModel);
          } else {
            setFilterModel(newModel);
          }
        }}
        // sort model
        sortModel={sortModel}
        onSortModelChange={(newModel) => {
          setSortModel(newModel);
          stateChangeCallbacks?.onSortModelChange?.(newModel);
        }}
        // pagination
        pagination
        paginationModel={paginationModel}
        onPaginationModelChange={(newModel) => {
          setPaginationModel(newModel);
        }}
        pageSizeOptions={slotProps?.datagrid?.pageSizeOptions ?? [10, 25, 100]}
        // selection
        disableRowSelectionOnClick={slotProps?.datagrid?.selectable ?? false}
        checkboxSelection={slotProps?.datagrid?.selectable ?? false}
        rowSelectionModel={rowSelectionModel}
        onRowSelectionModelChange={(newModel) => {
          setRowSelectionModel(newModel);
        }}
        localeText={{
          footerRowSelected: (rowCount) =>
            `${formatNumber(rowCount)} ${pluralize(
              singularRowName ?? 'row',
              rowCount,
              '',
              's'
            )} selected`,
        }}
        // various different slots
        slots={{
          toolbar: slotProps?.datagrid?.slots?.toolbar ?? DataGridToolbar,
          noRowsOverlay:
            slotProps?.datagrid?.slots?.noRowsOverlay ?? NoRowsOverlay,
          noResultsOverlay:
            slotProps?.datagrid?.slots?.noResultsOverlay ?? NoRowsOverlay,
          loadingOverlay:
            slotProps?.datagrid?.slots?.loadingOverlay ?? LoadingIcon,
          panel: () => null, // disable the panel that holds the default column visibility and filters since we're using custom ones
          // disable footer when loading
          ...{ ...(isLoading ? { footer: () => null } : {}) },
        }}
        slotProps={{
          loadingOverlay: {
            sx: {
              backgroundColor: '#FFF',
            },
          },
          toolbar: {
            customColumns: formattedColumns,
            columnVisibilityModel,
            totalRowCounts,
            filteredRowCounts,
            isLoading:
              isLoading ||
              isCalculatingTotalRowCounts ||
              isCalculatingFilteredRowCounts,
            slotProps: slotProps?.toolbar?.slotProps,
            singularRowName,
            padded: slotProps?.toolbar?.padded,
            disabled: slotProps?.toolbar?.disabled,
            extraToolBarButtons: slotProps?.toolbar?.extraToolBarButtons,
          },
          noRowsOverlay: {
            text:
              slotProps?.datagrid?.noRowsOverlayText ??
              'No data found. Please try changing your filters.',
          },
          noResultsOverlay: {
            text:
              slotProps?.datagrid?.noResultsOverlayText ??
              'No data found. Please try changing your filters.',
          },
          footer: {
            sx: {
              backgroundColor: theme.palette.common.white,
            },
          },
        }}
        {...cleanedProps}
      />
    </Stack>
  );
};
